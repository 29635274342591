<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-30 16:08:32
-->
<template>
  <el-dialog :title="form.pkId?$t('w_0209'):$t('w_0203')"
             :visible.sync="centerDialogVisible"
             width="30%"
             center>
    <el-form :model="form"
             :rules="rules"
             label-width="auto"
             ref="form">
      <el-form-item :label="$t('MY_ORD_10')"
                    prop="accountName">
        <el-input v-model="form.accountName"
                  disabled="disabled"
                  :placeholder="$t('w_0067')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('w_0052')"
                    prop="phone">
        <el-input v-model="form.phone"
                  disabled="disabled"
                  oninput="value = value.replace(/[^\d.]/g,'')"
                  :placeholder="$t('w_0053')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('w_0068')"
                    prop="dizhi"
                    class="dizhi">
        <el-cascader v-model="form.dizhi"
                     :options="areaData"
                     :props="regionParams"
                     :placeholder="$t('w_0069')"></el-cascader>
      </el-form-item>
      <el-form-item :label="$t('S_C_27')"
                    prop="address">
        <el-input v-model="form.address"
                  :placeholder="$t('w_0070')"
                  type="textarea"
                  maxlength="200"
                  :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="centerDialogVisible = false">{{$t('MY_ORD_24')}}</el-button>
      <el-button type="primary"
                 class="redBtn"
                 @click="submitForm('form')">{{$t('w_0035')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as api from '@/api/register.js'
import {getGiftAddress, saveGiftAddress} from "@/api/register.js";
export default {
  data() {
    return {
      centerDialogVisible: false,
      form: {
          dizhi: [],
          accountName: '',
          phone: '',
          address: '',
          pkProvince: '',
          pkCity: '',
          pkCounty: '',
      },
      rules: {
          accountName: [
          { required: true, message: '请输入收货人', trigger: 'blur' },
        ],
          phone: [
          { required: true, message: this.$t('w_0053'), trigger: 'blur' },
          { min: 8, max: 20, message: this.$t('w_0204'), trigger: 'blur' },
        ],
        dizhi: [
          { required: true, message: this.$t('w_0069'), trigger: 'change' },
        ],
          address: [
          { required: true, message: this.$t('w_0070'), trigger: 'blur' },
        ],
      },
      areaData: [],
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
    }
  },
  mounted() {
    // this.getAllArea()
  },
  methods: {
    isShowAddress(val, data, datas) {
      this.centerDialogVisible = val
        console.error(datas)
      if (data) {
        this.areaData = data
        this.form = {
          dizhi: [],
            accountName: '',
            phone: '',
            address: '',
            pkProvince: '',
            pkCity: '',
            pkCounty: '',
        }
        // this.$nextTick(() => {
        //   this.$refs.form.resetFields()
        // })
      }
      if (datas) {
        this.form.pkId = datas.pkId
        this.getAddresDetail(datas)
      } else {
        this.form.pkId = ''
      }
    },
    getAddresDetail(data) {
        console.error(data)
        this.form.accountName = data.accountName
        this.form.phone = data.phone
        this.form.dizhi = [
            data.pkProvince + '',
            data.pkCity + '',
            (data.pkCounty || '') + '',
        ]
        console.log(
            '%c [ this.form.dizhi ]-127',
            'font-size:13px; background:#753a79; color:#b97ebd;',
            this.form.dizhi
        )
        this.form.dizhi = this.form.dizhi.filter((s) => {
            return s && s.trim()
        })
        this.form.address = data.address
        console.error(this.form)
        this.$forceUpdate()
    },
    submitForm(formName) {
      this.form.pkProvince = this.form.dizhi[0]
      this.form.pkCity = this.form.dizhi[1]
      this.form.pkCounty = this.form.dizhi[2] || ''
      // this.form.isDefault = 0
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.saveGiftAddress(this.form).then((res) => {
            if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
              this.form = {
                dizhi: [],
                accountName: '',
                phone: '',
                address: '',
                  pkProvince: '',
                  pkCity: '',
                  pkCounty: '',
              }
              this.isShowAddress(false)
              this.$emit('getAdList1')
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dizhi {
  ::v-deep .el-cascader {
    width: 100%;
  }
}
::v-deep .el-cascader .el-input__suffix {
  right: 0;
}
.redBtn {
  background: #d61820;
  border-color: #d61820;
}
</style>