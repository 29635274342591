<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-03 15:24:48
-->
<template>
  <div class="main">
    <div class="main_l">
      <div :class="['main_li', isClick == item.id ? 'act' : '']"
           v-for="item in menuList"
           @click="(isClick = item.id), changeMenu()"
           :key="item.id">
        {{ item.text }}
      </div>
    </div>
    <!-- 个人资料 -->
    <div class="main_r"
         v-show="isClick == 0">
      <div class="main_rt">
        <div class="tit">{{ $t("w_0025") }}</div>
        <div class="contain">
          <el-form ref="form"
                   :model="form"
                   label-width="100px">
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_2')">
                  <el-input :maxlength="40" v-model="form.nickName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('MY_WAL_33')">
                  <el-input disabled
                            v-model="form.memberName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('N_I_124')">
                  <el-input disabled
                            v-model="form.memberCode"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
              <el-col :span="6">
                <el-form-item :label="$t('MN_F_23')">
                  <el-input disabled
                            v-model="form.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('MY_WAL_36')">
                  <el-input disabled
                            v-model="form.idCardAuth"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_3')">
                  <el-input disabled
                            v-model="form.countryName"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_4')">
                  <el-input disabled
                            v-model="form.settleCountryName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_5')">
                  <el-input disabled
                            v-model="form.registerGradeVal"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('CK_KS_15')">
                  <el-input disabled
                            v-model="form.settleGradeVal"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_7')">
                  <el-input disabled
                            v-model="form.agentGrade"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('ENU_NOTICE_GRADE_2')">
                  <el-input disabled
                            v-model="form.awardsVal"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="荣誉奖衔">
                  <el-input disabled
                            v-model="form.maxAwardsVal"></el-input>
                </el-form-item>
              </el-col>
            
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
                    <el-col :span="6">
                <el-form-item :label="$t('PER_DA_9')">
                  <el-input disabled
                            v-model="form.payTime"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_10')">
                  <el-input disabled
                            v-model="form.consumeAchieve"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('PER_DA_11')">
                  <el-input disabled
                            v-model="form.registerAuthorityVal"></el-input>
                </el-form-item>
              </el-col>
             
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between">
                    <el-col :span="6">
                <el-form-item :label="$t('MY_ORD_3')">
                  <el-input disabled
                            v-model="form.payTime"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex"
                    class="rowBto"
                    justify="space-between" v-if="form.serviceCode">
                    
              <el-col :span="6">
                <el-form-item :label="$t('w_1034')">
                  <el-input disabled
                            v-model="form.serviceCode"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="main_rb">
        <div class="redBtn"
             @click="saveNickName">{{ $t("PER_DA_12") }}</div>
      </div>
    </div>
    <!-- 电子会员卡 -->
    <div class="main_r"
         v-show="isClick == 5">
      <div class="card_title">{{ $t("PER_DA_13") }}</div>
      <div class="card-center"
           id="myImage">
        <div class="id-card1">
          <div class="bg-color"
               v-if="userInfo.headPath">
            <img :src="userInfo.headPath" />
            <!--            <div>{{ userInfo.pkAwardsVal }}</div>-->
          </div>
          <div class="card-content">
            <div class="icon">
              <img src="@/assets/images/logo.png" />
            </div>
            <div class="number"
                 style="color: #ffff00">
              {{ $t("N_I_124") }}:{{ userInfo.memberCode }}
            </div>
            <div class="number">
              {{ $t("PER_DA_14") }}：{{ userInfo.memberName }}
            </div>
            <div class="number">
              {{ $t("PER_DA_15") }}：{{ userInfo.pkGradeVal }}
            </div>
            <div class="number">
              {{ $t("S_C_83") }}：{{ userInfo.pkCountryVal }}
            </div>
            <div class="CN">
              {{ $t("S_L_4") }}：<span>{{ userInfo.countryCode }}</span>
            </div>
          </div>
        </div>
        <div class="id-card1">
          <div class="number margin_s">
            <!-- <div class="CN flexs">
              {{ $t("PER_DA_18") }}：{{ userInfo.globalCode }}
            </div> -->
            <div style=" margin-right: 30px">
              {{ $t("S_L_5") }}：{{ datatime(userInfo.registerTime) }}
            </div>
          </div>
          <div class="card-img">
            <div class="imged">
              <img v-if="userInfo.awardsIcon"
                   :src="userInfo.awardsIcon" />
              <div class="award">{{  form.awardsVal }}</div>
            </div>
          </div>
          <div class="icons">
            <div class="CN flexs"
                 style="color:#fff">
              {{ $t("PER_DA_18") }}：{{ userInfo.globalCode }}
            </div>
            <!-- <img src="@/assets/images/logo.png" /> -->
          </div>

          <div class="card-content"
               style="color: #fff; margin-top: 30px; font-size: 14px"></div>
        </div>
      </div>
      <div class="download"
           @click="saveImage">{{ $t("S_C_45") }}</div>
      <a id="link"></a>
    </div>
    <!-- 地址管理 -->
    <div class="main_r"
         v-show="isClick == 6">
      <div class="main_rt">
        <div class="tit">{{ $t("PER_DA_19") }}</div>
        <div class="tab_le">
          <el-table :data="addressArr1"
                    style="width: 100%"
                    :header-cell-style="{ background: '#EEEEEE' }"
                    :row-class-name="tableRowClassName">
            <el-table-column align="center"
                             prop="accountName"
                             :label="$t('MY_ORD_10')">
            </el-table-column>
            <el-table-column align="center"
                             prop="phone"
                             :label="$t('MN_F_23')">
            </el-table-column>
            <el-table-column align="center"
                             prop="pkProvinceVal"
                             :label="$t('MY_ORD_11')">
            </el-table-column>
            <el-table-column align="center"
                             prop="pkCityVal"
                             :label="$t('MY_ORD_12')">
            </el-table-column>
            <el-table-column align="center"
                             prop="pkCountyVal"
                             :label="$t('MY_ORD_13')">
            </el-table-column>
            <el-table-column align="center"
                             prop="address"
                             :label="$t('S_C_27')">
            </el-table-column>

            <el-table-column align="center"
                             prop="time"
                             :label="$t('MY_ORD_14')">
              <template slot-scope="scope">
                <el-button @click="changeAds1(scope.row)"
                           type="text"
                           size="small">
                  {{$t('PER_DA_20')}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 地址管理 -->
    <div class="main_r"
         v-show="isClick == 4">
      <div class="main_rt">
        <div class="tit"> {{$t('w_0027')}}</div>
        <div class="tab_le">
          <el-table :data="addressArr"
                    style="width: 100%"
                    :header-cell-style="{ background: '#EEEEEE' }"
                    :row-class-name="tableRowClassName">
            <el-table-column align="center"
                             prop="recName"
                             :label="$t('MY_ORD_10')">
            </el-table-column>
            <el-table-column align="center"
                             prop="recProvinceVal"
                             :label="$t('MY_ORD_11')">
            </el-table-column>
            <el-table-column align="center"
                             prop="recCityVal"
                             :label="$t('MY_ORD_12')">
            </el-table-column>
            <el-table-column align="center"
                             prop="recCountyVal"
                             :label="$t('MY_ORD_13')">
            </el-table-column>
            <el-table-column align="center"
                             prop="recAddress"
                             :label="$t('S_C_27')">
            </el-table-column>
            <el-table-column align="center"
                             prop="recPhone"
                             :label="$t('MN_F_23')">
            </el-table-column>
            <el-table-column align="center"
                             prop="time"
                             :label="$t('MY_ORD_14')">
              <template slot-scope="scope">
                <el-button @click="changeAds(scope.row.pkId)"
                           type="text"
                           size="small">
                  {{$t('PER_DA_20')}}
                </el-button>
                <el-button @click="delAds(scope.row.pkId)"
                           type="text"
                           size="small"
                           style="color: #c73030">
                  {{$t('CK_KS_47')}}
                </el-button>
                <div class="toSet"
                     v-if="scope.row.isDefault == 1">
                  {{$t('PER_DA_22')}}
                </div>
                <div class="noSet"
                     @click="setDefaultAds(scope.row.pkId)"
                     v-else>
                  {{$t('PER_DA_23')}}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center"
                             prop="numberPeriods"
                             label="">
              <template slot-scope="scope">
                <div class="toSet"
                     v-if="scope.row.isDefault == 1">当前为{{ $t('w_0074') }}</div>
                <div class="noSet"
                     @click="setDefaultAds(scope.row.pkId)"
                     v-else>设为默认</div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <div class="main_rb1">
        <div class="redBtn"
             @click="openDig">{{$t('PER_DA_24')}}</div>
      </div>
    </div>
    <!-- 我的头像 -->
    <div class="main_r"
         v-show="isClick == 1">
      <div class="main_rt">
        <div class="tit">{{$t('PER_DA_25')}}</div>
        <div class="myTou">
          <img :src="
              isEmpty(form.headPath) == false
                ? form.headPath
                : userInfo.countrySquareIcon
            "
               alt="" />
          <div class="myTou_1">{{$t('PER_DA_26')}}</div>

          <el-upload :action="uploadImgUrl"
                     multiple
                     :on-success="handleUploadSuccess"
                     :before-upload="handleBeforeUpload"
                     :headers="headers"
                     :file-list="fileList">
            <el-button size="small"
                       class="myTou_2"
                       type="primary">{{$t('PER_DA_27')}}</el-button>
            <div class="myTou_3">{{$t('PER_DA_28')}}</div>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- 账号安全 -->
    <div class="main_r"
         v-show="isClick == 2 && loginPwd == 2">
      <div class="main_rt">
        <div class="tit">{{$t('w_0026')}}</div>
        <div class="safty">
          <el-form ref="safty"
                   :model="safty"
                   label-width="auto">
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('w_0041')">
                  <el-input type="password"
                            disabled
                            v-model="safty.loginPwd"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      class="righTxt">
                <div @click="loginPwd = 1">{{$t('PER_DA_31')}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('N_I_217')">
                  <el-input type="password"
                            disabled
                            v-model="safty.payPwd"></el-input>
                  <div @click="getAuth"
                       class="resetPwd">{{ $t('ENU_RESET_PASSWORD_2') }}</div>
                  <!-- <div @click="loginPwd = 4"
                       class="resetPwd">{{ $t('ENU_RESET_PASSWORD_2') }}</div> -->
                </el-form-item>

              </el-col>
              <el-col :span="10"
                      class="righTxt">
                <div @click="loginPwd = 0">{{$t('PER_DA_32')}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('PER_DA_33')">
                  <el-input v-model="safty.email"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      class="righTxt">
                <div @click="loginPwd = 3">{{$t('PER_DA_34')}}</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('MN_F_23')">
                  <el-input v-model="safty.tel"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      class="righTxt">
                <!-- <div class="righTxt1i">
                  <i class="el-icon-success"
                     style="color: #009900;font-size: 16px;margin-right: 5px;"></i>已绑定
                </div> -->
                <div v-if="showFix == 0"
                     @click="sendChange">{{$t('PER_DA_35')}}</div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 修改登录密码 -->
    <div class="main_r"
         v-show="isClick == 2 && loginPwd == 1">
      <div class="main_rt">
        <div class="tit">{{$t('PER_DA_36')}}</div>
        <div class="safty">
          <el-form ref="loginObj"
                   :model="loginObj"
                   label-width="auto">
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('PER_DA_37')">
                  <el-input v-model="loginObj.oldPassword"
                            :type="flag ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag = !flag" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('w_0049')}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('w_0047')">
                  <el-input v-model="loginObj.newPassword"
                            :type="flag1 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag1 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag1 = !flag1" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('w_0049')}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('w_0050')">
                  <el-input v-model="loginObj.loginPassword"
                            :type="flag2 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag2 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag2 = !flag2" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('w_0049')}}
              </el-col>
            </el-row>
          </el-form>
          <div class="pwdBtn"
               @click="upLoginPwd(0)">{{$t('MY_WAL_23')}}</div>
        </div>
      </div>
    </div>
    <!-- 修改支付密码 -->
    <div class="main_r"
         v-show="isClick == 2 && loginPwd == 0">
      <div class="main_rt">
        <div class="tit">{{$t('PER_DA_41')}}</div>
        <div class="safty">
          <el-form ref="loginObj"
                   :model="loginObj"
                   label-width="auto">
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('PER_DA_37')">
                  <el-input v-model="loginObj.oldPassword"
                            :type="flag3 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag3 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag3 = !flag3" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      @click="loginPwd = 1"
                      class="righTxt">
                <!-- 6位数字，请尽量不要使用本人生日 -->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('MN_F_29')">
                  <el-input v-model="loginObj.newPassword"
                            :type="flag4 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag4 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag4 = !flag4" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('PER_DA_42')}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('w_0050')">
                  <el-input v-model="loginObj.payPassword"
                            :type="flag5 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag5 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag5 = !flag5" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('PER_DA_42')}}
              </el-col>
            </el-row>
          </el-form>
          <div class="pwdBtn"
               @click="upLoginPwd(1)">{{$t('MY_WAL_23')}}</div>
        </div>
      </div>
    </div>
    <!-- 重置支付密码 -->
    <div class="main_r"
         v-show="isClick == 2 && loginPwd == 4">
      <div class="main_rt">
        <div class="tit">{{ $t('ENU_RESET_PASSWORD_2') }}</div>
        <div class="safty">
          <el-form ref="loginObj"
                   :model="loginObj"
                   label-width="80px">
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('MN_F_29')">
                  <el-input v-model="loginObj.newPassword"
                            :type="flag4 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag4 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag4 = !flag4" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('PER_DA_42')}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <el-form-item :label="$t('w_0050')">
                  <el-input v-model="loginObj.payPassword"
                            :type="flag5 ? 'text' : 'password'"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('S_C_70')">
                    <i slot="suffix"
                       :class="[flag5 ? 'el-icon-minus' : 'el-icon-view']"
                       style="margin-top: 8px; font-size: 18px"
                       autocomplete="auto"
                       @click="flag5 = !flag5" />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      @click="loginPwd = 1"
                      class="righTxt">
                {{$t('PER_DA_42')}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="14">
                <!-- <el-form-item :label="$t('w_0043')"
                              prop="smsCode">
                  <el-input v-model="loginObj.smsCode"
                            style="border: 1px solid #fff; width: 250px"
                            :placeholder="$t('w_0187')"></el-input>
                  <i slot="suffix"
                     @click="submitForm('emailObj')"
                     class="hqyzm1"
                     :class="isSend ? 'hui' : ''">{{ getSmsCode }}</i>
                </el-form-item> -->
                <el-form-item :label="$t('w_0043')">
                  <el-input v-model="loginObj.code"
                            style="border: 1px solid #fff; width: 250px">
                    <i slot="suffix"
                       @click="submitForm1"
                       class="hqyzm1"
                       :class="isSend ? 'hui' : ''">{{ getSmsCode }}</i>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10"
                      class="righTxt">
              </el-col>
            </el-row>
          </el-form>
          <div class="pwdBtn"
               @click="upLoginPwd(4)">{{$t('MY_WAL_23')}}</div>
        </div>
      </div>
    </div>
    <!-- 修改邮箱 -->
    <div class="main_r"
         v-show="isClick == 2 && loginPwd == 3">
      <div class="main_rt">
        <div class="tit">{{$t('PER_DA_43')}}</div>
        <div class="safty1">
          <el-form ref="emailObj"
                   :model="emailObj"
                   label-width="auto">
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('PER_DA_44')">
                  <el-input v-model="emailObj.emailed"
                            disabled> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('w_0043')">
                  <el-input v-model="emailObj.code">
                    <i slot="suffix"
                       @click="submitForm('emailObj')"
                       class="hqyzm1"
                       :class="isSend ? 'hui' : ''">{{ getSmsCode }}</i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('PER_DA_45')">
                  <el-input v-model="emailObj.email"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="pwdBtn"
               @click="upEmailObj">{{$t('MY_WAL_23')}}</div>
        </div>
      </div>
    </div>
    <AddAddress ref="addAddress"
                @getAdList="getAddressList"></AddAddress>
    <giftAddress ref="addAddress1"
                 @getAdList1="getAddressList1"></giftAddress>
  </div>
</template>

<script>
import * as api from '@/api/person.js'
import * as ads from '@/api/register.js'
import AddAddress from '@/components/AddAddress.vue'
import giftAddress from '@/components/giftAddress.vue'
import { getToken } from '@/util/auth'
import user from '@/store/modules/user'
import { mapGetters } from 'vuex'
import html2canvas from 'html2canvas'
import { checkIfShow } from '@/api/wallet.js'
import { numberToCurrencyNo } from '@/util/numberToCurrency'

import { getMemberGift } from '@/api/person.js'
import { getCard } from '../../api/person'
export default {
  components: {
    AddAddress,
    giftAddress,
  },
  computed: {
    user() {
      return user
    },
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      isSend: false,
      getSmsCode: this.$t('w_0055'),
      beginTime: 60,
      timer: '',
      flag: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      flag5: false,
      loginPwd: 2,
      safty: {
        loginPwd: 111111,
        payPwd: 111111,
        email: 111111,
        tel: 111111,
      },
      loginObj: {
        oldPassword: '',
        loginPassword: '',
        payPassword: '',
        newPassword: '',
      },
      emailObj: {
        emailed: '',
        code: '',
        email: '',
      },
      headers: {
        Authorization: getToken(),
      },
      uploadImgUrl:
        process.env.VUE_APP_BASE_API + '/member/api/member/update-head', // 上传的图片服务器地址
      isClick: 0,
      fileList: [],
      menuList: [
        {
          id: 0,
          text: this.$t('w_0025'),
        },
        {
          id: 1,
          text: this.$t('PER_DA_25'),
        },
        {
          id: 2,
          text: this.$t('w_0026'),
        },
        // {
        //   id: 3,
        //   text: '会员积分',
        // },
        {
          id: 6,
          text: this.$t('PER_DA_19'),
        },
        {
          id: 4,
          text: this.$t('w_0027'),
        },
        {
          id: 5,
          text: this.$t('PER_DA_13'),
        },
      ],
      form: {
        headPath: '',
      },
      addressArr: [],
      addressArr1: [],
      areaData: [],
      touImg: require('@/assets/images/prize.jpg'),
      file: '',
      userInfos: '',
      showFix: 1,
    }
  },
  watch: {
    $route(n, o) {
      this.isClick = n.query.id
    },
  },
  mounted() {
    this.isClick = this.$route.query.id
    this.getMemberInfo()
    this.getAddressList()
    this.getAddressList1()
    this.getAllArea()
    this.checkIfShow()
    this.getCardInfo()
  },
  methods: {
    checkIfShow() {
      checkIfShow().then((res) => {
        this.showFix = res.data.existDomicile
      })
    },
    getCardInfo() {
      api.getCard().then((res) => {
        this.userInfos = res.data
        if (res.data.gradeIconStr) {
          this.userInfos.gradeIconStr =
            'data:image/png;base64,' + res.data.gradeIconStr
          this.userInfos.gradeIconStr.replace(/[\r\n]/g, '')
        }
        if (res.data.awardsIconStr) {
          this.userInfos.awardsIconStr =
            'data:image/png;base64,' + res.data.awardsIconStr
          this.userInfos.awardsIconStr.replace(/[\r\n]/g, '')
        }
      })
    }, 
    getAuth() {
      ads.authenticationDetails().then((res) => {
        if (res.data) {
          this.loginPwd = 4
        } else {
          this.$confirm(`${this.$t('MY_WAL_50')}?`, this.$t('w_0034'), {
            confirmButtonText: this.$t('w_0035'),
            cancelButtonText: this.$t('ENU_P_TYPE0'),
            type: 'warning',
          })
            .then(() => {
              this.$router.push({
                path: '/vipCenter',
                query: { id: 5, atabActiveId: 9 },
              })
            })
            .catch(() => {})
        }
      })
    },
    isEmpty(v) {
      switch (typeof v) {
        case 'undefined':
          return true
        case 'string':
          if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0)
            return true
          break
        case 'boolean':
          if (!v) return true
          break
        case 'number':
          if (0 === v || isNaN(v)) return true
          break
        case 'object':
          if (null === v || v.length === 0) return true
          for (var i in v) {
            return false
          }
          return true
      }
      return false
    },
    saveImage() {
      const options = {
        backgroundColor: null, // null或transparent可将canvas背景设置为透明
        allowTaint: false,
        useCORS: true /*使用跨域*/,
      }
      html2canvas(document.querySelector('#myImage'), options).then(
        (canvas) => {
          const image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          const link = document.getElementById('link')
          link.setAttribute('download', '电子会员卡.png')
          link.setAttribute(
            'href',
            canvas
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream')
          )
          link.click()
        }
      )
    },
    datatime(value) {
      var data = new Date(value)
      const month =
        data.getMonth() < 9 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1
      var date = data.getDate() <= 9 ? '0' + data.getDate() : data.getDate()
      let dates = data.getFullYear() + '-' + month + '-' + date
      return dates
    },
    sendChange() {
      this.$router.push({
        path: '/vipCenter',
        query: { id: 5, atabActiveId: 3 },
      })
    },
    // 绑卡获取验证码
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.startTime()
          api
            .verification()
            .then((res) => {
              // this.bankCode = res.data
            })
            .catch((err) => {
              this.beginTime = 0
            })
        } else {
          return false
        }
      })
    },
    submitForm1() {
      this.startTime()
      api
        .selfVerification()
        .then((res) => {
          // this.bankCode = res.data
        })
        .catch((err) => {
          this.beginTime = 0
        })
    },
    startTime() {
      if (this.isSend) return
      this.isSend = true
      this.getSmsCode = this.beginTime + this.$t('w_0058')
      let that = this
      this.timer = setInterval(() => {
        this.beginTime--
        this.getSmsCode = this.beginTime + this.$t('w_0058')
        if (this.beginTime < 0) {
          clearInterval(this.timer)
          this.getSmsCode = this.$t('w_0055')
          this.beginTime = 60
          this.isSend = false
        }
      }, 1000)
    },
    changeMenu() {
      this.loginPwd = 2
      this.loginObj = {
        oldPassword: '',
        loginPassword: '',
        payPassword: '',
        newPassword: '',
      }
    },
    upEmailObj() {
      api.updateEmail(this.emailObj).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.emailObj = {
            emailed: '',
            code: '',
            email: '',
          }
          this.loginPwd = 2
          this.getMemberInfo()
        }
      })
    },
    upLoginPwd(index) {
      let reqUrl
      if (index == 0) {
        if (this.loginObj.newPassword != this.loginObj.loginPassword) {
          this.$message({
            type: 'warning',
            message: this.$t('PER_DA_46'),
          })
          return false
        }
        reqUrl = api.updatePwd
      } else if (index == 1) {
        if (this.loginObj.newPassword != this.loginObj.payPassword) {
          this.$message({
            type: 'warning',
            message: this.$t('PER_DA_46'),
          })
          return false
        }
        reqUrl = api.updatePwd
      } else if (index == 4) {
        if (this.loginObj.newPassword != this.loginObj.payPassword) {
          this.$message({
            type: 'warning',
            message: this.$t('PER_DA_46'),
          })
          return false
        }
        reqUrl = api.forgetPassword
      }

      // loginObj
      reqUrl(this.loginObj).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.loginObj = {
            oldPassword: '',
            loginPassword: '',
            payPassword: '',
            newPassword: '',
          }
          this.loginPwd = 2
        }
      })
    },
    handleUploadSuccess(res) {
      // this.form.headPath = res.data.url;
      this.$set(this.form, 'headPath', res.data.url)
      this.loading.close()
    },
    upHeader() {
      api.memberInfoHeadUpdate().then((res = {}))
    },
    handleBeforeUpload() {
      this.loading = this.$loading({
        lock: true,
        text: this.$t('PER_DA_47'),
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    setDefaultAds(id) {
      ads.addressDefault(id).then((res) => {
        this.getAddressList()
      })
    },
    delAds(id) {
      this.$confirm(this.$t('w_0088'), '', {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then(() => {
        ads.delAddress(id).then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getAddressList()
        })
      })
    },
    getAllArea() {
      ads.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    openDig() {
      this.$refs.addAddress.isShowAddress(true, this.areaData)
    },
    changeAds1(data) {
      this.$refs.addAddress1.isShowAddress(true, this.areaData, data)
    },
    changeAds(id) {
      this.$refs.addAddress.isShowAddress(true, this.areaData, id)
    },
    getAddressList1() {
      api.getMemberGift().then((res) => {
        this.addressArr1 = res.data
      })
    },
    getAddressList() {
      api.addressList().then((res) => {
        this.addressArr = res.data
      })
    },
    getMemberInfo() {
      api.memberInfo().then((res) => {
        this.form = res.data
        // numberToCurrencyNo
        this.form.consumeAchieve = numberToCurrencyNo(this.form.consumeAchieve)
        //   safty: {
        //   loginPwd: 111111,
        //   payPwd: 111111,
        //   email: 111111,
        //   tel: 111111,
        // },
        this.safty.email = res.data.email || ''
        this.emailObj.emailed = res.data.email || ''
        this.safty.tel = res.data.phone || ''
      })
    },
    saveNickName() {
      api
        .memberInfoUpdate({
          nickName: this.form.nickName,
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          // this.form = res.data
        })
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'warning-row'
      } else if (rowIndex % 2 == 0) {
        return 'success-row'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.flexs {
  display: flex;
  justify-content: space-between;
}
.icons {
  position: absolute;
  margin: 10px 0 0 20px;
  img {
    width: 33px;
    height: 23px;
  }
}
.download {
  width: 116px;
  height: 35px;
  background: #d61820;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  line-height: 35px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.card-center {
  padding: 200px 0px 60px 0;
  display: flex;
  justify-content: center;
  .id-card1 {
    width: 440px;
    height: 256px;
    //background: linear-gradient(180deg, #12db74 0%, #048b46 100%);
    border-radius: 10px 10px 10px 10px;
    background: url('~@/assets/images/id-card-bg.png') no-repeat;
    margin-right: 40px;
    background-size: 100% 100%;
    position: relative;
    .bg-color {
      position: absolute;
      right: 30px;
      top: 5px;
      //bottom: 50px;
      div {
        color: #fff;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
      }
      img {
        width: 144px;
        // height: 184px;
      }
    }
    .card-img {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 256px;
      position: absolute;
    }
    .imged {
      left: 50%;
      top: 5%;
      //margin-left: -70px;
      //margin-top: -70px;
      position: absolute;
      border-radius: 10px;
      img {
        width: 135px;
        height: 135px;
      }
    }
    .award {
      text-align: center;
      color: #fff;
    }
    .card-content {
      width: 400px;
      //height: 210px;
      position: absolute;
      padding: 15px;
      z-index: 1;
      .icon {
        margin-bottom: 10px;
        img {
          width: 33px;
          height: 23px;
        }
      }
      .CN {
        font-size: 10px;
        color: #fff;
        margin-top: 45px;
        span {
          font-size: 18px;
        }
      }
    }
  }
}
.number {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.margin_s {
  //padding-top: 180px;
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.main {
  min-height: calc(100vh - 90px);
  display: flex;
  .main_l {
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px #ededed;
    border-radius: 8px 8px 8px 8px;
    padding: 25px 10px;
    .main_li {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      padding: 20px 73px;
      cursor: pointer;
    }
    .act {
      background: #d61820;
      border-radius: 10px 10px 10px 10px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .main_r {
    flex: 1;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    .main_rt {
      flex: 1;
      .tit {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        padding: 20px 0 10px 20px;
        border-bottom: 5px solid rgba(0, 0, 0, 0.05);
      }
      .contain {
        padding: 36px 20px;
        ::v-deep .el-form-item__label {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        .rowBto {
          margin-bottom: 40px;
        }
      }
      .tab_le {
        padding: 10px 60px 10px 20px;
      }
      .myTou {
        text-align: center;
        margin-top: 15%;
        img {
          width: 88px;
          height: 88px;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 10px 10px 10px 10px;
        }
        .img {
          width: 88px;
          height: 88px;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 10px 10px 10px 10px;
          margin: 0 auto;
        }
        .myTou_1 {
          font-size: 18px;
          font-weight: 600;
          color: #333333;
          margin: 20px 0 20px 0;
        }
        .myTou_2 {
          width: 116px;
          height: 35px;
          background: #d61820;
          border-radius: 8px 8px 8px 8px;
          margin: 0 auto 20px auto;
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          border: 0;
        }
        .myTou_3 {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .safty {
        text-align: center;
        width: 40%;
        margin: 100px auto 0 auto;
        ::v-deep .el-row {
          display: flex;
          align-items: center;
        }
      }
      .safty1 {
        text-align: center;
        width: 20%;
        margin: 100px auto 0 auto;
        ::v-deep .el-row {
          display: flex;
          align-items: center;
        }
      }
    }
    .main_rb {
      height: 60px;
      background: #ffffff;
      box-shadow: 0px -3px 20px 0px rgba(204, 204, 204, 0.5);
      text-align: center;
      padding: 10px 0;
      .redBtn {
        width: 290px;
        height: 48px;
        line-height: 48px;
        background: #d61820;
        border-radius: 5px 5px 5px 5px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto;
        // cursor: pointer;
      }
    }
    .main_rb1 {
      height: 48px;
      text-align: center;
      padding: 10px 0;
      .redBtn {
        cursor: pointer;
        width: 290px;
        height: 48px;
        line-height: 48px;
        background: #d61820;
        border-radius: 5px 5px 5px 5px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto;
      }
    }
  }
}
.toSet {
  color: #b42b2a;
  cursor: pointer;
}
.noSet {
  cursor: pointer;
}
::v-deep .el-upload-list {
  display: none;
}
::v-deep .el-form-item {
  margin-bottom: 25px;
}
.righTxt {
  margin-bottom: 25px;
  font-size: 14px;
  color: #b42b2a;
  cursor: pointer;
  display: flex;
  margin-left: 20px;
}
.righTxt1 {
  margin-bottom: 25px;
  font-size: 14px;
  // color: #b42b2a;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
}
.righTxt1i {
  display: flex;
  align-items: center;
  color: #666;
  margin-right: 10px;
}
.pwdBtn {
  margin-top: 20px;
  width: 290px;
  height: 66px;
  background: #d61820;
  border-radius: 10px 10px 10px 10px;

  font-size: 22px;
  color: #ffffff;
  text-align: center;
  line-height: 66px;
  margin: 0 auto;
}
.hqyzm1 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0099ff;
  cursor: pointer;
  margin-right: 10px;
  font-style: normal;
}
.hui {
  opacity: 0.5;
}
.card_title {
  font-size: 18px;
  color: #333;
  //margin: 0 20px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.05);
  padding: 40px 20px 10px 20px;
}
.resetPwd {
  text-align: left;
  line-height: 0;
  margin-top: 20px;
  font-size: 12px;
  color: #0099ff;
  cursor: pointer;
}
</style>
