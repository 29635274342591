import request from '@/util/request'

// 交易明细
export function getTransactionDetails(query) {
  return request({
    url: '/member/api/wallet/transaction-details?pageNum=' + query.pageNum + "&pageSize=" + query.pageSize,
    method: 'post',
    data: query
  })
}

//钱包余额
export function getWalletBalance(params) {
  return request({
    url: '/member/api/wallet/balance',
    method: 'get',
    params
  })
}

//交易类型下拉选
export function getTradeTypeList(params) {
  return request({
    url:'/member/api/wallet/trade-type',
    // url: '/system/pub/enums/get-trade-type',
    method: 'get',
    params
  })
}

//币种-账户类型下拉选
export function getEnumsAccountList(params) {
  return request({
    url: '/system/api/account/pkBdAccountList',
    method: 'get',
    params
  })
}

//钱包银行卡列表
export function getBankList(params) {
  return request({
    url: '/member/api/bank/list',
    method: 'get',
    params
  })
}

//钱包绑卡
export function bindWalletBankAdd(data) {
  return request({
    url: '/member/api/bank/add',
    method: 'post',
    data
  })
}

//绑定银行卡校验
export function verifyBankCard(data) {
  return request({
    url: '/member/api/bank/verify-bank-card',
    method: 'post',
    data
  })
}

//是否配置白名单 白名单不进行银行卡四要素校验
export function checkIfWhite(params) {
  return request({
    url: '/member/api/bank/is-white-list',
    method: 'get',
    params
  })
}


//解除绑定
export function removeBankBind(params) {
  return request({
    url: '/member/api/bank/unbind-bank',
    method: 'get',
    params
  })
}

//银行卡选择下拉选
export function getBankCardChoiceList(params) {
  return request({
    url: '/system/api/bank/list',
    method: 'get',
    params
  })
}

//获取转账转出账户
export function getTransferAccount(params) {
  return request({
    url: '/member/api/transfer/transfer-account',
    method: 'get',
    params
  })
}

//根据用户编号查询用户姓名
export function getTransferChineseName(params) {
  return request({
    url: '/member/api/transfer/chinese-name',
    method: 'get',
    params
  })
}

//确认转账
export function addTransfer(data) {
  return request({
    url: '/member/api/transfer/add',
    method: 'post',
    data
  })
}

//确认提现
export function addWithdraw(data) {
  return request({
    url: '/member/api/withdraw/add',
    method: 'post',
    data
  })
}

//提现账户 
export function getPkBdAccountList(params) {
  return request({
    url: '/system/api/account/pkBdAccountList',
    method: 'get',
    params
  })
}

//提现选择银行卡
export function getDefaultBank(params) {
  return request({
    url: '/member/api/bank/default-bank',
    method: 'get',
    params
  })
}

//校验是否绑定银行卡
export function checkIsbindBank(params) {
  return request({
    url: '/member/api/bank/is-bind',
    method: 'get',
    params
  })
}



//成长之路-等级
export function getGrowUpGrade(params) {
  return request({
    url: '/member/api/member-footmark/grow-up-footmark-grade',
    method: 'get',
    params
  })
}

//成长之路-奖衔
export function getGrowUpAwards(params) {
  return request({
    url: '/member/api/member-footmark/grow-up-footmark-awards',
    method: 'get',
    params
  })
}

//获取银行验证码
export function getVerification(params) {
  return request({
    url: '/member/api/sms/verification',
    method: 'get',
    params
  })
}

//是否进行实名认证
export function getIfAuth(params) {
  return request({
    url: '/member/api/authentication/is-auth',
    method: 'get',
    params
  })
}

//是否上传营业执照
export function getIsExist(params) {
  return request({
    url: '/member/api/business/license/is-exist',
    method: 'get',
    params
  })
}

//上传营业执照
export function addLicense(data) {
  return request({
    url: '/member/api/business/license/add',
    method: 'post',
    data
  })
}

//营业执照详情
export function getLicenseDetail(params) {
  return request({
    url: '/member/api/business/license/detail',
    method: 'get',
    params
  })
}

//修改营业执照
export function updateLicense(data) {
  return request({
    url: '/member/api/business/license/update',
    method: 'post',
    data
  })
}

//证件类型
export function getCardType(params) {
  return request({
    url: '/system/pub/enums/id-type',
    method: 'get',
    params
  })
}

//实名认证
export function addAuthentication(data) {
  return request({
    url: '/member/api/authentication/add',
    method: 'post',
    data
  })
}

// 获取推荐列表
export function parentList(params) {
  return request({
    url: '/member/api/member/parent-list',
    method: 'get',
    params
  })
}

// 保存目标
export function saveGoals(data) {
  return request({
    url: '/member/api/goals/save-goals',
    method: 'post',
    data
  })
}
// 显示目标
export function showGoals(params) {
  return request({
    url: '/member/api/goals/show-goals',
    method: 'get',
    params
  })
}
// 获取用户是否需要弹出修改密码 0需要弹出 >不弹
export function getUserUpdatePWd(params) {
  return request({
    url: '/member/api/member/get-member-is-updatepassword',
    method: 'get',
    params
  })
}

export function getTransferMember(params) {
  return request({
    url: '/member/api/transfer/member-balance',
    method: 'get',
    params
  })
}

//二级密码校验
export function checkPwd(params) {
  return request({
    url: '/member/api/recharge/check-pwd',
    method: 'get',
    params
  })
}

//提现页面展示
export function getWidthdrawShow(params) {
  return request({
    url: '/member/api/withdraw/show/add',
    method: 'get',
    params
  })
}

//自助服务权限
export function checkIfShow(params) {
  return request({
    url: '/member/api/self/authority',
    method: 'get',
    params
  })
}

//提现明细列表
export function getWithdrawList(params) {
  return request({
    url: '/member/api/withdraw/list-detail',
    method: 'get',
    params
  })
}

//提现撤销
export function cancelWithdraw(params) {
  return request({
    url: '/member/api/withdraw/cancel',
    method: 'get',
    params
  })
}

//设为默认银行卡
export function updateDefault(params) {
  return request({
    url: '/member/api/bank/update-default',
    method: 'get',
    params
  })
}







