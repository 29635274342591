<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-30 16:08:32
-->
<template>
	<el-dialog :title="form.pkId?$t('ENU_TRADE_T_900'):$t('w_0203')" :visible.sync="centerDialogVisible" width="30%"
		center>
		<el-form :model="form" :rules="rules" label-width="auto" ref="form">
			<el-form-item :label="$t('MY_ORD_10')" prop="recName">
				<el-input v-model="form.recName" :maxlength="40" :placeholder="$t('w_0067')"></el-input>
			</el-form-item>
			<el-form-item :label="$t('w_0052')" prop="recPhone" v-if="pkCountry == 1">
				<el-input v-model="form.recPhone" :maxlength="11" oninput="value = value.replace(/[^\d.]/g,'')"
					:placeholder="$t('w_0053')"></el-input>
			</el-form-item>
			<el-form-item :label="$t('w_0052')" prop="recPhone" v-else>
				<el-input v-model="form.recPhone" :maxlength="30" :placeholder="$t('w_0053')">
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('w_0068')" prop="dizhi" class="dizhi">
				<el-cascader v-model="form.dizhi" :options="areaData" :props="regionParams"
					:placeholder="$t('w_0069')"></el-cascader>
			</el-form-item>
			<el-form-item :label="$t('S_C_27')" prop="recAddress">
				<el-input v-model="form.recAddress" :placeholder="$t('w_0070')" type="textarea" maxlength="200"
					:rows="2"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="centerDialogVisible = false">{{$t('MY_ORD_24')}}</el-button>
			<el-button type="primary" class="redBtn" @click="submitForm('form')">{{$t('w_0035')}}</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import * as api from '@/api/register.js'
	import {
		mapGetters
	} from 'vuex'

	export default {
		data() {
			let validateRecPhone = (rule, value, callback) => {
				if (this.pkCountry == 1 && this.form.recPhone.length != 11) {
					callback(new Error(this.$t('w_0204')))
				} else if (this.pkCountry == 1 && this.form.recPhone[0] != 1) {
					callback(new Error(this.$t("w_0204")));
				} else {
					callback()
				}
			}
			return {
				centerDialogVisible: false,
				form: {
					dizhi: [],
					recName: '',
					recPhone: '',
					recAddress: '',
					recProvince: '',
					recCity: '',
					recCounty: '',
				},
				rules: {
					recName: [{
						required: true,
						message: this.$t('w_0067'),
						trigger: 'blur'
					}, ],
					recPhone: [{
						required: true,
						validator: validateRecPhone,
						trigger: 'blur'
					}, ],
					dizhi: [{
						required: true,
						message: this.$t('w_0069'),
						trigger: 'change'
					}, ],
					recAddress: [{
						required: true,
						message: this.$t('w_0070'),
						trigger: 'blur'
					}, ],
				},
				areaData: [],
				regionParams: {
					label: 'name',
					value: 'id',
					children: 'children',
					expandTrigger: 'hover',
				},
				pkCountry: '',
			}
		},
		computed: {
			...mapGetters(['userInfo']),
		},
		mounted() {
			// this.getAllArea()
		},
		methods: {
			isShowAddress(val, data, id) {
				this.centerDialogVisible = val
				this.pkCountry = parseInt(localStorage.getItem('pkCountry'))
				if (data) {
					this.areaData = data
					this.form = {
						dizhi: [],
						recName: '',
						recPhone: '',
						recAddress: '',
						recProvince: '',
						recCity: '',
						recCounty: '',
					}
					// this.$nextTick(() => {
					//   this.$refs.form.resetFields()
					// })
				}
				if (id) {
					this.form.pkId = id
					this.getAddresDetail()
				} else {
					this.form.pkId = ''
				}
			},
			getAddresDetail() {
				api.addressDetail(this.form.pkId).then((res) => {
					this.form.recName = res.data.recName
					this.form.recPhone = res.data.recPhone
					this.form.dizhi = [
						res.data.recProvince + '',
						res.data.recCity + '',
						(res.data.recCounty || '') + '',
					]
					console.log(
						'%c [ this.form.dizhi ]-127',
						'font-size:13px; background:#753a79; color:#b97ebd;',
						this.form.dizhi
					)
					this.form.dizhi = this.form.dizhi.filter((s) => {
						return s && s.trim()
					})
					this.form.recAddress = res.data.recAddress
				})
				this.$forceUpdate()
			},
			submitForm(formName) {
				this.form.recProvince = this.form.dizhi[0]
				this.form.recCity = this.form.dizhi[1]
				this.form.recCounty = this.form.dizhi[2] || ''
				this.form.isDefault = 0
				this.$refs[formName].validate((valid) => {
					if (valid) {
						api.saveAddress(this.form).then((res) => {
							if (res.code == 200) {
								this.form = {
									dizhi: [],
									recName: '',
									recPhone: '',
									recAddress: '',
									recProvince: '',
									recCity: '',
									recCounty: '',
								}
								this.isShowAddress(false)
								this.$emit('getAdList')
							}
						})
					} else {
						return false
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.dizhi {
		::v-deep .el-cascader {
			width: 100%;
		}
	}

	::v-deep .el-cascader .el-input__suffix {
		right: 0;
	}

	.redBtn {
		background: #d61820;
		border-color: #d61820;
	}
</style>