/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-03 15:41:50
 */
import request from '@/util/request'

// 获取个人资料
export function memberInfo(params) {
  return request({
    url: '/member/api/member/get-data',
    method: 'get',
    params
  })
}
// 地址列标
export function addressList(params) {
  return request({
    url: '/member/api/member-address/list',
    method: 'get',
    params
  })
}
// 会员地址列表
export function getMemberGift(params) {
  return request({
    url: '/member/api/member-gift/member-gift-list',
    method: 'get',
    params
  })
}
// 更新昵称
export function memberInfoUpdate(data) {
  return request({
    url: '/member/api/member/update-data',
    method: 'put',
    data
  })
}
// 更新头像
export function memberInfoHeadUpdate(data) {
  return request({
    url: '/member/api/member/update-head',
    method: 'put',
    data
  })
}
// 更新密码
export function updatePwd(data) {
  return request({
    url: '/member/api/member/update-password',
    method: 'put',
    data
  })
}
// 验证码
export function verification(params) {
  return request({
    url: '/member/api/sms/verification',
    method: 'get',
    params
  })
}
// 当前用户短信验证码
export function selfVerification(params) {
  return request({
    url: '/member/api/sms/self-verification',
    method: 'get',
    params
  })
}
// 更新邮箱
export function updateEmail(data) {
  return request({
    url: '/member/api/member/update-email',
    method: 'put',
    data
  })
}
// 更新支付密码
export function forgetPassword(data) {
  return request({
    url: '/member/api/member/forget-pay-password',
    method: 'put',
    data
  })
}
// 获取会员卡信息
export function getCard(params) {
  return request({
    url: '/member/api/member/get-card',
    method: 'get',
    params
  })
}